// 激活相关接口
import { instance_demo } from '@/utils/middleHttp.js'

// 获取验证码
export const getverifyM = function (params) {
  return instance_demo({
    url: '/api/v1/stucard/verify',
    method: 'get',
    params
  })
}

// 激活
export const toActivateM = function (data) {
  return instance_demo({
    url: '/api/v1/stucard/activate',
    method: 'post',
    data
  })
}

// 年级-初中
export const getGradeM = function (params) {
  return instance_demo({
    url: '/api/v1/stucard/grade',
    method: 'get',
    params
  })
}

// 地区-初中
export const getDisM = function (params) {
  return instance_demo({
    url: '/api/v1/stucard/district',
    method: 'get',
    params
  })
}

// // 地区-科目
// export const getSubject = function (params) {
//   return instance_demo({
//     url: '/api/v1/stucard/subject',
//     method: 'get',
//     params
//   })
// }

// 城市-初中
export const getCityM = function (params) {
  return instance_demo({
    url: '/api/v1/stucard/get_city_tree',
    method: 'get',
    params
  })
}



// 基本信息-初中
export const registerM = function (data) {
  return instance_demo({
    url: '/api/v1/stucard/register',
    method: 'post',
    data
  })
}




