import axios from 'axios'
import basicUrl from './basicUrl'
import { Message, Loading } from 'element-ui'

export const instance_demo = axios.create({
  baseURL: basicUrl,
  timeout: 3600000,
});

export var requestSign = {
  number: 0,
  setNumber: function (number) {
    requestSign.number = number
  }
}

export var loadings
function openLoading () {
  // 开启全局loading 
  if (!requestSign.number) {
    loadings = Loading.service({
      fullscreen: true,
      target: document.querySelector('#app'),
      text: '正在加载中!',
      spinner: 'el-icon-loading'
    });
  }
  requestSign.number += 1
}

function closeLoading () {
  // return
  setTimeout(() => {
    requestSign.number -= 1
    if (requestSign.number < 0) {
      requestSign.number = 0
    }
    if (requestSign.number == 0) {
      loadings.close()
    }
  }, 500);
}

function addInterceptors (face) {
  face.interceptors.request.use(config => {
    // if (config.method == 'get') {
    //   if (!config.params) {
    //     config.params = {}
    //   }
    // } else {
    //   if (!config.data) {
    //     config.data = {}
    //   }
    // }
    openLoading()
    return config
  })
  face.interceptors.response.use(async config => {
    closeLoading()
    config = config.data
    return await new Promise((resolve, reject) => {
      if (config.status == 200) {
        resolve(config)
      } else {
        // 当状态码不为200的时候，直接拦截
        if (config.msg == 'error') {
          Message.error(config.data.msg)
        } else {
          Message.error(config.msg)
        }
        reject(config)
      }
    })
  })
}
addInterceptors(instance_demo)
